import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import PDFViewer from "../components/pdf-viewer";
import "../styles/pages/membership.scss";

export default function Downloads() {
  return (
    <Layout title="Membership">
      <h1 className="page-title">Membership</h1>
      <div className="membership">
        <div className="membership-section">
          <h3>Eligibility</h3>
          <ul>
            <li>
              Nookampalayam Link Road- Semmancheri Residents who have completed
              18 years of age only can be admitted as member of the association.
            </li>

            <li>
              The Executive Committee can accept or reject the applications for
              becoming members of the Association without any explanation and/or
              any prior notice.
            </li>

            <li>
              Every member of the Association shall have one vote. Any member
              who is unable to attend the meeting shall authorize in writing to
              the President, or his/her designated representative to vote on
              his/her behalf.
            </li>
          </ul>
        </div>

        <div className="membership-section">
          <h3>Membership Charges</h3>
          <ul>
            <li>
              Every owner shall pay a fee of <strong>Rs. 500/-</strong> to
              obtain membership in the association and monthly subscription of
              Charges of <strong>Rs.50/-</strong>
              (or) as decided fixed amount in General Body Meeting.
            </li>

            <li>
              If any member is in arrears of the subscription for more than one
              year, shall he/she automatically cease to be a member of the
              Association.
            </li>
          </ul>
        </div>

        <div className="membership-section">
          <h3>How to become member?</h3>
          <ol>
            <li>
              Download the membership form below first:
              <PDFViewer
                id="membership-form"
                fileData={{
                  url: "https://res.cloudinary.com/sivadass/image/upload/v1639312205/nlrrwa/documents/NLRRWA-Membership-Form.pdf",
                  fileName: "NLRRWA-Membership-Form.pdf",
                }}
              />
            </li>

            <li>
              Take printout of this form and fill in all the owner details.
            </li>

            <li>
              Make the payment by following instructions in the{" "}
              <Link to="/pay-online">payment page</Link>.
            </li>

            <li>
              Take a picture of the filled in form in good lighting and your
              payment screenshot and then send it to{" "}
              <a href="tel:+919176276511">+91+91762-76511</a> via WhatsApp.
            </li>
            <li>
              Our treasury will give you payment receipt after saving your
              membership details and verifying your payment.
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  );
}
