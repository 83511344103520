class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      }
    });
    this.adobeDCView = undefined;
  }

  ready() {
    return this.readyPromise;
  }

  previewFile(divId, fileData) {
    const config = {
      clientId: process.env.ADOBE_PDF_API_KEY,
    };
    if (divId) {
      config.divId = divId;
    }
    this.adobeDCView = new window.AdobeDC.View(config);
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: {
          location: {
            url: fileData.url,
          },
        },
        metaData: {
          fileName: fileData.fileName,
        },
      },
      {
        defaultViewMode: "FIT_WIDTH",
        showAnnotationTools: false,
        showLeftHandPanel: false,
        showPageControls: false,
        showPrintPDF: false,
      }
    );
    return previewFilePromise;
  }
}

export default ViewSDKClient;
