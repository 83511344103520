import React, { useEffect } from "react";
import Helmet from "react-helmet";
import ViewSDKClient from "../utils/pdf-viewer-helper";
import "../styles/components/pdf-viewer.scss";

const PDFViewer = ({ fileData, id }) => {
  useEffect(() => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(id, fileData);
    });
  }, []);
  return (
    <>
      <Helmet>
        <script src="https://documentcloud.adobe.com/view-sdk/main.js"></script>
      </Helmet>
      <div
        id={`${id}`}
        className="full-window-div"
        style={{
          width: "100%",
          height: 450,
          borderRadius: `16px 16px  0 0`,
          border: `1px solid #17507c`,
        }}
      />
      <div className="pdf-download">
        <a
          target="_blank"
          href={fileData.url}
          download
        >{`Download ${fileData.fileName}`}</a>
      </div>
    </>
  );
};

export default PDFViewer;
